import axios from 'axios';
import config from 'react-global-configuration';

const ExportReport = dataRequest => {
  const { start, end, token, hospCode, type } = dataRequest;
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${config.get('ROOT_API')}/v1/refer/export-refer?hospCode=${hospCode}&start=${start}&end=${end}&type=${type}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        if (res.status === 200) {
          let data = {
            exportReport: res.data,
            FetchingStatus: false,
          };
          return resolve(data);
        } else {
          let data = {
            exportReport: res.data,
            FetchingStatus: false,
          };
          return resolve(data);
        }
      })
      .catch(error => {
        console.log('error fetch export refer', error.message);
        return reject(error.response);
      });
  });
};

export default ExportReport;
