import { SUPERADMIN, ADMIN, REFER_MANAGER, REFER_USER, REPORT_USER, AMPHOE_ADMIN } from '../constants/Users/userRole';

export const getPermission = ({ role }) => {
  const permission = {
    viewHospitalReport: false,
    viewProvinceReport: false,
    viewRefer: false,
    actionRefer: false,
    userManagement: false,
    hospitalManagement: false,
    addUserMember: false,
    exportReferData: false,
  };

  switch (role?.toUpperCase()) {
    case SUPERADMIN:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = true;
      permission.viewRefer = false;
      permission.actionRefer = false;
      permission.userManagement = true;
      permission.hospitalManagement = true;
      permission.addUserMember = true;
      permission.exportReferData = false;
      break;
    case AMPHOE_ADMIN:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = false;
      permission.actionRefer = false;
      permission.userManagement = true;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.exportReferData = false;
      break;
    case ADMIN:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = true;
      permission.userManagement = true;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.exportReferData = true;
      break;
    case REFER_MANAGER:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = true;
      permission.userManagement = false;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.exportReferData = true;
      break;
    case REFER_USER:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = true;
      permission.actionRefer = false;
      permission.userManagement = false;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.exportReferData = false;
      break;
    case REPORT_USER:
      permission.viewHospitalReport = true;
      permission.viewProvinceReport = false;
      permission.viewRefer = false;
      permission.actionRefer = false;
      permission.userManagement = false;
      permission.hospitalManagement = false;
      permission.addUserMember = false;
      permission.exportReferData = false;
      break;

    default:
      break;
  }

  return permission;
};
