import React, { Component } from 'react';
import { connect } from 'react-redux';
import DayjsUtils from '@date-io/dayjs';
import 'dayjs/locale/th';
import dayjs from 'dayjs';
import orm from '../../models/index';
import { Parser } from 'json2csv';

// constants
import { AMPHOE_ADMIN, SUPERADMIN } from '../../constants/Users/userRole';
import { REFER_BACK, REFER_IN, REFER_OUT } from '../../constants/Refers/referType';

// actions
import { appPropertySet } from '../../actions/AppProperty';
import { fetchRefers, fetchReportExport } from '../../actions/ReferDatas';
import { fetchHospitalList } from '../../actions/UserManagement';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// functions
import { DateTimeToStrDate, addDays, delDays } from '../../functions/FuncDateTimes';

// components
import { ReferInReport } from './ReferInReport';
import { ReferOutReport } from './ReferOutReport';
import { ReferBackReport } from './ReferBackReport';

// utilities
import { ListboxComponent } from '../Utility/ListboxComponent';

// mui
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-thai-datepickers';
import Grid from '@material-ui/core/Grid';
import { CircularProgress, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FilterListIcon from '@material-ui/icons/FilterList';
import Menu from '@material-ui/core/Menu';
import { saveAs } from 'file-saver';

const session = orm.session();

class ReferReportMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProcessStatus: false,
      hospCodeData: [],
      ReferDatas: [],
      type: '',
      selectedStartDate: new Date(),
      selectedEndDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      maxDateStart: new Date(),
      maxDateEnd: new Date(),
      minDateEnd: new Date(),
      selectedHospital: {},
      viewChildHospitalReport: false,
      filterDeathPatient: false,
      anchorEl: null,
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleFilterClose = this.handleFilterClose.bind(this);
    this.referTypeChange = this.referTypeChange.bind(this);
    this.getReferDatas = this.getReferDatas.bind(this);
    this.setReferDatas = this.setReferDatas.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    if (
      this.props.appData?.loginData?.role?.toUpperCase() === SUPERADMIN ||
      this.props.appData?.loginData?.role?.toUpperCase() === AMPHOE_ADMIN
    ) {
      this?.setState({ viewChildHospitalReport: true });
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      prevState?.viewChildHospitalReport !== this?.state?.viewChildHospitalReport &&
      this?.state?.viewChildHospitalReport
    ) {
      this.props.fetchHospitalList({ idToken: this.props.appData.idToken, limit: '1000' });
    }
  }

  handleStartDateChange(date) {
    this.setState({ selectedStartDate: date });

    const monthRollsOver = addDays(new Date(date), 93);
    if (monthRollsOver < this.state.selectedEndDate) {
      this.setState({ selectedEndDate: monthRollsOver });
      this.setState({ maxDateEnd: monthRollsOver });
    }

    if (monthRollsOver > new Date()) {
      this.setState({ maxDateEnd: new Date() });
    } else {
      this.setState({ maxDateEnd: monthRollsOver });
    }

    if (date < this.state.maxDateEnd) {
      this.setState({ minDateEnd: date });
      if (date >= this.state.selectedEndDate) {
        this.setState({ selectedEndDate: date });
      }
    }

    if (this.state.maxDateEnd < date) {
      this.setState({ selectedEndDate: date });
      this.setState({ minDateEnd: date });
    }
  }

  handleEndDateChange(date) {
    this.setState({ selectedEndDate: date });

    const monthRollsOver = delDays(new Date(date), 93);
    if (monthRollsOver > this.state.selectedStartDate) {
      this.setState({ selectedStartDate: monthRollsOver });
    }
  }

  referTypeChange(e) {
    this.setState({ ProcessStatus: false });
    this.setState({ type: e.target.value });
  }

  getReferDatas(startDate, endDate, type, filterDeathPatient) {
    this.setState({ ProcessStatus: false });
    if (type === '') {
      alert('กรุณาเลือกประเภทการส่งต่อ (Refer Type)');
    } else {
      let dataRequest = null;

      if (type === REFER_IN) {
        dataRequest = {
          idToken: this.props.appData.idToken,
          startDate: startDate,
          endDate: endDate,
          fromHospCode: '',
          toHospCode: this?.state?.viewChildHospitalReport
            ? this.state?.selectedHospital?.hospCode
            : this.props.appData.loginData.hospCode,
          limit: 100000,
          filterDeathPatient,
        };
      } else if (type === REFER_BACK) {
        dataRequest = {
          idToken: this.props.appData.idToken,
          startDate: startDate,
          endDate: endDate,
          fromHospCode: this?.state?.viewChildHospitalReport
            ? this.state?.selectedHospital?.hospCode
            : this.props.appData.loginData.hospCode,
          toHospCode: '',
          limit: 100000,
          type: type,
          filterDeathPatient,
        };
      } else {
        dataRequest = {
          idToken: this.props.appData.idToken,
          startDate: startDate,
          endDate: endDate,
          fromHospCode: this?.state?.viewChildHospitalReport
            ? this.state?.selectedHospital?.hospCode
            : this.props.appData.loginData.hospCode,
          toHospCode: '',
          limit: 100000,
          filterDeathPatient,
        };
      }

      let res = this.props.fetchRefers(dataRequest);

      let obj = this;
      res.then(
        function (v) {
          obj.setReferDatas();
        },
        function (e) {
          if (e.status !== 200) {
            alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
          } else {
          }
        }
      );
    }
  }

  setReferDatas() {
    const referDb = this.props.appData.emrData.referDatas;

    session.ReferDatas.delete({
      where(record) {
        return record._id !== '';
      },
    });

    for (var val of referDb) {
      session.ReferDatas.upsert(val);
    }

    let referData = session.ReferDatas.all()
      .filter(ReferDatas => ReferDatas._id !== '')
      .orderBy(ReferDatas => ReferDatas.referDateTime, 'desc')
      .toRefArray();

    this.setState({ ReferDatas: referData });
    this.setState({ ProcessStatus: true });
  }

  handleFilterClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleFilterClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleDownload = async () => {
    const startM = new Date(this.state.selectedStartDate).getMonth();
    const endM = new Date(this.state.selectedEndDate).getMonth();
    for (let index = startM; index <= endM; index++) {
      const start = dayjs().month(index).startOf('M').format('YYYY-MM-DD');
      const end = dayjs().month(index).endOf('M').format('YYYY-MM-DD');
      try {
        const result = await this.props.fetchReportExport({
          start,
          end,
          token: this.props.appData.idToken,
          hospCode: this.props?.appData?.hospCode,
          type: this.state.type,
        });
        const { headers: fields, rows } = result.value.exportReport;
        const json2csvParser = new Parser({ fields });
        const csv = json2csvParser.parse(rows);
        saveAs(new Blob([csv], { type: 'text/csv' }), `export-refer-${start}-${end}.csv`);
      } catch (error) {}
    }
  };

  render() {
    return (
      <div className='margin-bottom-8px'>
        <AppBar
          position='fixed'
          className='box-shadow-custom MuiAppBar-color-custom patient-main'
          style={{ top: '55px' }}
        >
          <Toolbar variant='dense' className='refer-bar'>
            <Grid container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} md={7}>
                <Grid container alignItems='center' spacing={2}>
                  <Grid item xs={12} sm={2} md={1}>
                    <h6 className='margin-bottom-0px margin-top-8px'>Refer</h6>
                  </Grid>

                  {this?.state?.viewChildHospitalReport && (
                    <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete
                        fullWidth
                        disableListWrap
                        loading={!this.props.appData?.hospitalList?.docs[0]}
                        ListboxComponent={ListboxComponent}
                        options={this.props.appData?.hospitalList?.docs}
                        onChange={(option, value) => this.setState({ selectedHospital: value })}
                        getOptionLabel={option => `${option.hospCode} : ${option.hospName}`}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            placeholder='เลือกโรงพยาบาล'
                            variant='standard'
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {!this.props.appData?.hospitalList?.docs[0] && (
                                    <CircularProgress color='inherit' size={20} />
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={6} sm={4} md={2}>
                    <MuiPickersUtilsProvider utils={DayjsUtils} locale='th'>
                      <DatePicker
                        className='date-time-picker icon-button'
                        autoOk
                        value={this.state.selectedStartDate}
                        onChange={date => this.handleStartDateChange(date)}
                        disableToolbar
                        variant='inline'
                        maxDate={this.state.maxDateStart}
                        views={['year', 'month', 'date']}
                        format='DD/MM/yyyy'
                        yearOffset={543}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} sm={4} md={2}>
                    <MuiPickersUtilsProvider utils={DayjsUtils} locale='th'>
                      <DatePicker
                        className='date-time-picker icon-button'
                        autoOk
                        value={this.state.selectedEndDate}
                        onChange={date => this.handleEndDateChange(date)}
                        disableToolbar
                        variant='inline'
                        minDate={this.state.minDateEnd}
                        maxDate={this.state.maxDateEnd}
                        views={['year', 'month', 'date']}
                        format='DD/MM/yyyy'
                        yearOffset={543}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={5}>
                <Grid container alignItems='center' justifyContent='flex-end' spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant='filled'>
                      <Select displayEmpty value={this.state.type} onChange={this.referTypeChange}>
                        <MenuItem value=''>Refer Type</MenuItem>
                        <MenuItem value={REFER_IN}>Refer In</MenuItem>
                        <MenuItem value={REFER_OUT}>Refer Out</MenuItem>
                        <MenuItem value={REFER_BACK}>Refer Back</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} sm={2} md={2}>
                    <Tooltip title='Filter'>
                      <IconButton onClick={this.handleFilterClick}>
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={this.handleFilterClose}
                    >
                      <MenuItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size='small'
                              checked={this.state.filterDeathPatient}
                              onChange={() =>
                                this.setState({
                                  filterDeathPatient: !this.state.filterDeathPatient,
                                })
                              }
                            />
                          }
                          label='Deceased'
                        />
                      </MenuItem>
                    </Menu>
                  </Grid>

                  <Grid item xs={8} sm={4} md={6} style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      className='icon-button'
                      startIcon={<CachedIcon />}
                      onClick={() =>
                        this.getReferDatas(
                          DateTimeToStrDate(this.state.selectedStartDate),
                          DateTimeToStrDate(this.state.selectedEndDate),
                          this.state.type,
                          this.state.filterDeathPatient
                        )
                      }
                    >
                      Process
                    </Button>
                    {this.props.appData.permission?.exportReferData && (
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        className='icon-button'
                        onClick={this.handleDownload}
                      >
                        Download
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        {this.state.ReferDatas.length > 0 &&
          this.state.ProcessStatus &&
          ['REFER_IN', 'REFER_OUT', 'REFER_BACK'].includes(this.state.type) && (
            <div className='text-align-left' style={{ marginTop: this.props.width <= 991 ? '95px' : '45px' }}>
              {this.state.type === REFER_IN && (
                <ReferInReport
                  ReferDatas={this.state.ReferDatas}
                  StartDate={this.state.selectedStartDate}
                  EndDate={this.state.selectedEndDate}
                  filterDeathPatient={this.state.filterDeathPatient}
                />
              )}

              {this.state.type === REFER_OUT && (
                <ReferOutReport
                  ReferDatas={this.state.ReferDatas}
                  StartDate={this.state.selectedStartDate}
                  EndDate={this.state.selectedEndDate}
                  filterDeathPatient={this.state.filterDeathPatient}
                />
              )}

              {this.state.type === REFER_BACK && (
                <ReferBackReport
                  ReferDatas={this.state.ReferDatas}
                  StartDate={this.state.selectedStartDate}
                  EndDate={this.state.selectedEndDate}
                  filterDeathPatient={this.state.filterDeathPatient}
                />
              )}
            </div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchRefers: dataRequest => dispatch(fetchRefers(dataRequest)),
    fetchHospitalList: ({ idToken, page, limit, sortColumn, sortType, filterText, filterList }) =>
      dispatch(fetchHospitalList({ idToken, page, limit, sortColumn, sortType, filterText, filterList })),
    fetchReportExport: dataRequest => dispatch(fetchReportExport(dataRequest)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferReportMain);
