import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import orm from '../../../models/index';

// actions
import { appPropertySet } from '../../../actions/AppProperty';
import { fetchRefersAccept } from '../../../actions/ReferAccept';
import { fetchRefersReject } from '../../../actions/ReferReject';
import { fetchPatientsVisitsDetailsSet } from '../../../actions/PatientsVisitsDetails';
import { fetchReferNotificationsRead } from '../../../actions/ReferNotificationsRead';
import { deleteAttachment } from '../../../actions/Refers/ReferOut';

// functions
import { ThaiDateTime, ThaiDate } from '../../../functions/FuncDateTimes';
import { shortHospName } from '../../../functions/FuncPerjer';
import { getReferType, getReferColor } from '../../../functions/Refers';

// constants
import { PENDING, REJECTED } from '../../../constants/Refers/referralStatus';
import { REFER_BACK, REFER_IN, REFER_OUT } from '../../../constants/Refers/referType';

// components
import { ReferAcceptDialog, ReferRejectDialog, ReferAttachDialog, SimpleDialog } from '../../Utility/Refers';

import { StyledButton } from './VisitsReferrals.style';

// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// mui
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Link from '@material-ui/core/Link';
import { Divider } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const session = orm.session();

function VisitsRefer({
  bid,
  appData,
  appProperty,
  fetchRefersAccept,
  fetchRefersReject,
  fetchPatientsVisitsDetailsSet,
  fetchReferNotificationsRead,
  printClick,
}) {
  const [visitData, setvisitData] = useState(null);
  const [bidData, setDataBid] = useState(false);
  const [anchorEl, setAnchorEl] = useState([]);
  const [RefreshRefer, setRefreshRefer] = useState(false);
  const [DialogAcceptOpen, setDialogAcceptOpen] = useState(false);
  const [DialogRejectOpen, setDialogRejectOpen] = useState(false);
  const [DialogAttachOpen, setDialogAttachOpen] = useState(false);
  const [reason, setReason] = useState([]);
  const [isExpandedRequestText, setIsExpandedRequestText] = useState(false);
  const [isExpandedOtherText, setIsExpandedOtherText] = useState(false);
  const [isExpandedTreatmentText, setIsExpandedTreatmentText] = useState(false);
  const [isExpandedPmh, setIsExpandedPmh] = useState(false);
  const [isExpandedHpi, setIsExpandedHpi] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  useEffect(() => {
    if (bid !== bidData && !RefreshRefer) {
      setDataBidFc(bid);
      setvisitDataFc(bid);
    }
    if (RefreshRefer) {
      setRefreshReferFc(false);
      setTimeout(() => {
        setvisitDataFc(bid);
      }, 500);
    }
  });

  useEffect(() => {
    if (printClick) {
      setIsExpandedRequestText(true);
      setIsExpandedOtherText(true);
      setIsExpandedTreatmentText(true);
      setIsExpandedPmh(true);
      setIsExpandedHpi(true);
    } else {
      setIsExpandedRequestText(false);
      setIsExpandedOtherText(false);
      setIsExpandedTreatmentText(false);
      setIsExpandedPmh(false);
      setIsExpandedHpi(false);
    }
  }, [printClick]);

  const renderCardAction = ({ refIndex, referItems, items }) => {
    let referInAction = false;
    let referOutAction = false;
    const referType = getReferType({
      hospCode: appData?.loginData?.hospCode,
      fromHospCode: referItems?.data?.fromHospCode,
    });

    // refer in and status is pending or incomplete
    if (referType === REFER_IN && referItems?.status?.toUpperCase() === PENDING) {
      referInAction = true;
    }

    // refer out and status isn't rejected
    if (referType === REFER_OUT && referItems?.status?.toUpperCase() !== REJECTED) {
      referOutAction = true;
    }

    return (
      <>
        {(referInAction || referOutAction) && (
          <IconButton aria-label='settings' onClick={e => handleClick(e, refIndex)}>
            <MoreVertIcon />
          </IconButton>
        )}
        <Menu
          key={refIndex}
          id={'refer-action-menu' + refIndex}
          anchorEl={anchorEl[refIndex]}
          keepMounted
          open={Boolean(anchorEl[refIndex])}
          onClose={() => handleClose(refIndex)}
          className='refer-action-menu'
        >
          {referInAction && (
            <>
              <MenuItem onClick={() => DialogAcceptOpenFc(refIndex)}>Accept (ยอมรับ Refer)</MenuItem>
              <MenuItem onClick={() => DialogRejectOpenFc(refIndex)}>Reject (ปฏิเสธ Refer)</MenuItem>
              <MenuItem onClick={() => DialogAttachOpenFc(refIndex)}>แนบไฟล์</MenuItem>
            </>
          )}

          {referOutAction && <MenuItem onClick={() => DialogAttachOpenFc(refIndex)}>แนบไฟล์</MenuItem>}
        </Menu>

        <ReferAcceptDialog
          open={Boolean(DialogAcceptOpen[refIndex])}
          cid={referItems?.data?.cid}
          ptname={
            appData?.emrData?.patients?.data?.fullname?.title +
            ' ' +
            appData?.emrData?.patients?.data?.fullname?.firstName +
            ' ' +
            appData?.emrData?.patients?.data?.fullname?.lastName
          }
          hospName={referItems?.data?.fromHospName}
          onClose={() => DialogAcceptCloseFc(refIndex)}
          onSubmit={appointmentInfo =>
            ReferAcceptSubmit(refIndex, referItems?.bid, items.cid, items.bid, appointmentInfo)
          }
        />

        <ReferRejectDialog
          open={Boolean(DialogRejectOpen[refIndex])}
          cid={referItems?.data?.cid}
          ptname={
            appData?.emrData?.patients?.data?.fullname?.title +
            ' ' +
            appData?.emrData?.patients?.data?.fullname?.firstName +
            ' ' +
            appData?.emrData?.patients?.data?.fullname?.lastName
          }
          hospName={referItems?.data?.fromHospName}
          reason={reason[refIndex]}
          onClose={() => DialogRejectCloseFc(refIndex)}
          onChange={e => onReasonChange(e, refIndex)}
          onSubmit={() => ReferRejectSubmit(refIndex, referItems?.bid, items.cid, items.bid)}
        />

        <ReferAttachDialog
          idToken={appData?.idToken}
          open={Boolean(DialogAttachOpen[refIndex])}
          onClose={() => DialogAttachCloseFc(refIndex, referItems?.data?.cid)}
          bid={referItems?.bid}
          attachments={referItems?.attachments}
        />
      </>
    );
  };

  const onReasonChange = (e, id) => {
    setReason({
      [id]: e.target.value,
    });
  };

  const setDataBidFc = bid => {
    setDataBid(bid);
  };

  const setRefreshReferFc = value => {
    setRefreshRefer(value);
  };

  const setvisitDataFc = bid => {
    const visits = appData.emrData.visits;

    session.Visits.delete({
      where(record) {
        return record.bid === bid;
      },
    });

    for (var val of visits) {
      session.Visits.upsert(val);
    }

    let visitsData = session.Visits.all()
      .filter(Visits => Visits.bid === bid)
      .toRefArray();

    for (let val2 of visitsData[0].referrals) {
      if (val2) {
        NotifyRead(val2.bid);
      }
    }

    setvisitData({ visitsData });
  };

  const NotifyRead = bid => {
    const { update, getByIndex } = useIndexedDB('notifications');

    if (bid) {
      let userId =
        appData.loginData.username.substring(4, 5) +
        appData.loginData.username.substring(2, 3) +
        appData.loginData.username.substring(1, 2) +
        appData.loginData.username.substring(5, 6) +
        appData.loginData.username.substring(3, 4) +
        appData.loginData.username.substring(6, 7) +
        appData.loginData.username.substring(7, 8) +
        appData.loginData.username.substring(10, 11) +
        appData.loginData.username.substring(9, 10) +
        appData.loginData.username.substring(8, 9) +
        appData.loginData.username.substring(0, 1);

      let keyId = bid + userId;

      getByIndex('keyId', keyId).then(NotifyFromDB => {
        if (NotifyFromDB) {
          update({
            id: NotifyFromDB.id,
            keyId: NotifyFromDB.keyId,
            key1: NotifyFromDB.key1,
            key2: NotifyFromDB.key2,
            notifyDateTime: NotifyFromDB.notifyDateTime,
            notifyType: NotifyFromDB.notifyType,
            notifyLink: NotifyFromDB.notifyLink,
            datas: NotifyFromDB.datas,
            notifyRead: true,
          });

          if (NotifyFromDB.notifyRead === false) {
            let dataRequest = {
              idToken: appData.idToken,
              bid: bid,
              hospCode: appData.loginData.hospCode,
            };

            fetchReferNotificationsRead(dataRequest);
          }
        }
      });
    }
  };

  const handleClick = (event, id) => {
    setAnchorEl({
      [id]: event.currentTarget,
    });
  };

  const handleClose = id => {
    setAnchorEl({
      [id]: null,
    });
  };

  const DialogAcceptOpenFc = id => {
    setDialogAcceptOpen({
      [id]: true,
    });

    handleClose(id);
  };

  const DialogAcceptCloseFc = id => {
    setDialogAcceptOpen({
      [id]: false,
    });
  };

  const DialogRejectOpenFc = id => {
    setDialogRejectOpen({
      [id]: true,
    });

    handleClose(id);
  };

  const DialogRejectCloseFc = id => {
    setDialogRejectOpen({
      [id]: false,
    });
  };

  const DialogAttachOpenFc = id => {
    setDialogAttachOpen({
      [id]: true,
    });

    handleClose(id);
  };

  const DialogAttachCloseFc = (id, cid) => {
    setDialogAttachOpen({
      [id]: false,
    });

    RefreshPtData(cid);
  };

  const ReferAcceptSubmit = (id, bid, cid, visitBid, appointmentInfo) => {
    if (bid) {
      let dataRequest = {
        ...appointmentInfo,
        idToken: appData.idToken,
        bid: bid,
      };

      let res = fetchRefersAccept(dataRequest);

      res.then(
        function (v) {
          // ccccc
          RefreshPtData(cid, visitBid);
          DialogAcceptCloseFc(id);
        },
        function (e) {
          // TypeError: Throwing
          if (e.status !== 200) {
            alert('การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
          } else {
          }
        }
      );
    }
  };

  const ReferRejectSubmit = (id, bid, cid, visitBid) => {
    if (!reason[id] || reason[id] === '' || reason[id] === undefined || reason[id] === null) {
      alert('คุณไม่ได้กรอกข้อมูลเหตุผลการปฏิเสธ !');
    } else {
      if (bid) {
        let txt = reason[id];

        let dataRequest = {
          idToken: appData.idToken,
          bid: bid,
          reason: txt,
        };

        let res = fetchRefersReject(dataRequest);

        res.then(
          function (v) {
            // ccccc
            RefreshPtData(cid, visitBid);
            DialogRejectCloseFc(id);
          },
          function (e) {
            // TypeError: Throwing
            if (e.status !== 200) {
              alert('การบันทึกข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
            } else {
            }
          }
        );
      }
    }
  };

  const RefreshPtData = (cid, visitBid) => {
    let dataRequest = {
      idToken: appData.idToken,
      cid: cid,
      hn: '',
      hospCode: appData.loginData.hospCode,
      limit: appProperty.visitLimit,
    };

    let res = fetchPatientsVisitsDetailsSet(dataRequest);
    res.then(
      function (v) {
        setRefreshReferFc(true);
      },
      function (e) {
        // TypeError: Throwing
        if (e.status !== 200) {
          alert('การค้นห้าข้อมูลมีปัญหากรุณาลองใหม่อีกครั้ง !');
        }
      }
    );
  };

  const handleDeleteFile = async ({ idToken, id, cid }) => {
    await deleteAttachment({ idToken, id });

    setSelectedAttachment(null);

    RefreshPtData(cid);
  };

  return (
    <div>
      {visitData && (
        <div>
          {visitData.visitsData.map((items, index) => (
            <div key={index}>
              {items.referrals.map((referItems, refIndex) => (
                <Card
                  variant='outlined'
                  className={`margin-bottom-8px ${
                    appData?.emrData?.patients?.data?.deceasedBoolean
                      ? 'referrals-card-deceased'
                      : getReferColor({
                          referral: referItems,
                          pendingBackgroundShow: true,
                        })
                  }`}
                  key={refIndex}
                >
                  <CardHeader
                    className='card-header'
                    title={'Referral ID : ' + referItems?.data?.referoutId}
                    subheader={'เลข Refer : ' + referItems?.data?.referNumber}
                    action={renderCardAction({ refIndex, referItems, items })}
                  />
                  <CardContent className='font-size-14px refer-text-custom'>
                    <Row>
                      <Col lg={6}>
                        <h6>
                          สถานะ :{' '}
                          {referItems?.status?.toUpperCase() === PENDING && referItems?.data?.incomplete
                            ? 'ข้อมูลไม่ครบถ้วน'
                            : referItems?.status?.toUpperCase()}
                        </h6>
                      </Col>
                      <Col lg={6}>
                        <h6>เหตุผลการปฏิเสธ : {referItems?.rejectReason}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <h6>วันที่ส่งตัว : {ThaiDateTime(referItems?.data?.referDateTime)}</h6>
                      </Col>
                      <Col lg={6}>
                        <h6>
                          วันหมดอายุ :{' '}
                          {referItems?.data?.expireDateTime
                            ? ThaiDate(referItems?.data?.expireDateTime)
                            : referItems?.data?.referDateTime
                            ? `${ThaiDate(dayjs(referItems?.data?.referDateTime).add(3, 'months'))} (อัตโนมัติ)`
                            : '-'}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <h6>
                          รพ. ต้นทาง : {referItems?.fromHospital?.hospCode}{' '}
                          {shortHospName(referItems?.fromHospital?.hospName)}
                        </h6>
                      </Col>
                      <Col lg={6}>
                        <h6>
                          รพ. ปลายทาง : {referItems?.toHospital?.hospCode}{' '}
                          {shortHospName(referItems?.toHospital?.hospName)}
                        </h6>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <h6>กรณี : {referItems?.data?.referoutTypeName}</h6>
                      </Col>
                      <Col lg={6}>
                        <h6>ประเภทอุบัติเหตุ : {referItems?.data?.referoutEmergencyTypeName}</h6>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <h6>วันเวลาที่ตอบรับ : {ThaiDateTime(referItems?.statusLastUpdated)}</h6>
                      </Col>
                      <Col lg={6}>
                        <h6>
                          {referItems?.status?.toUpperCase() === REJECTED ? 'ผู้ปฏิเสธ' : 'ผู้ตอบรับ'} :{' '}
                          {referItems?.statusLastUpdatedByName || '-'}
                        </h6>
                      </Col>
                    </Row>

                    {/* in case refer out show refer type */}
                    {getReferType({
                      hospCode: appData?.loginData?.hospCode,
                      fromHospCode: referItems?.data?.fromHospCode,
                    }) === REFER_OUT && (
                      <Row>
                        <Col lg={6}>
                          <h6>Refer type : {referItems?.type === REFER_BACK ? 'Refer back' : 'Refer out'}</h6>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col lg={12}>
                        <h6>สิทธิการรักษา : {referItems?.data?.pttypeName}</h6>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>
                        <h6>ICD10 : {referItems?.data?.icd + ' - ' + referItems?.data?.icdName}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <h6>Pre Diagnosis : {referItems?.data?.preDiagnosis}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <h6>เหตุผล : {referItems?.data?.refername}</h6>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={12}>ไฟล์แนบ : </Col>
                      {referItems?.attachments &&
                        referItems?.attachments.length > 0 &&
                        referItems?.attachments.map(
                          file =>
                            file && (
                              <Col lg={12}>
                                <StyledButton
                                  startIcon={<ClearIcon />}
                                  size='small'
                                  color='secondary'
                                  onClick={() => setSelectedAttachment(file)}
                                />
                                <Link
                                  href={`https://ipfs0.ccs.evernetwork.io/ipfs/${file.ipfs_file_hash}`}
                                  target='_blank'
                                >
                                  - {file.ipfs_file_name}
                                </Link>
                              </Col>
                            )
                        )}
                    </Row>

                    {referItems?.appointmentDateTime && (
                      <>
                        <Divider />

                        <Row>
                          <Col lg={12}>
                            <h6></h6>
                            <h6>ข้อมูลตอบรับ</h6>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={6}>
                            <h6>วันเวลานัด : {ThaiDateTime(referItems?.appointmentDateTime)}</h6>
                          </Col>
                          <Col lg={6}>
                            <h6>จุดนัดหมาย : {referItems?.appointmentPoint}</h6>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={12}>
                            <h6>หมายเหตุ : {referItems?.appointmentNotes}</h6>
                          </Col>
                        </Row>
                      </>
                    )}

                    {referItems?.data?.requestText !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel
                            className='MuiAppBar-color-custom '
                            expanded={isExpandedRequestText}
                            onChange={e =>
                              isExpandedRequestText ? setIsExpandedRequestText(false) : setIsExpandedRequestText(true)
                            }
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>REQUEST TEXT :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems?.data?.requestText && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems?.data?.requestText.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>{item2}</h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}

                    {referItems?.data?.otherText !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel
                            className='MuiAppBar-color-custom '
                            expanded={isExpandedOtherText}
                            onChange={e =>
                              isExpandedOtherText ? setIsExpandedOtherText(false) : setIsExpandedOtherText(true)
                            }
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>OTHER TEXT :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems?.data?.otherText && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems?.data?.otherText.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>{item2}</h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}

                    {referItems?.data?.treatmentText !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel
                            className='MuiAppBar-color-custom '
                            expanded={isExpandedTreatmentText}
                            onChange={e =>
                              isExpandedTreatmentText
                                ? setIsExpandedTreatmentText(false)
                                : setIsExpandedTreatmentText(true)
                            }
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>TREATMENT :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems?.data?.treatmentText && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems?.data?.treatmentText.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>{item2}</h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}

                    {referItems?.data?.pmh !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel
                            className='MuiAppBar-color-custom '
                            expanded={isExpandedPmh}
                            onChange={e => (isExpandedPmh ? setIsExpandedPmh(false) : setIsExpandedPmh(true))}
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>Past medical history :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems?.data?.pmh && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems?.data?.pmh.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>{item2}</h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}

                    {referItems?.data?.hpi !== '' && (
                      <Row>
                        <Col lg={12}>
                          <ExpansionPanel
                            className='MuiAppBar-color-custom '
                            expanded={isExpandedHpi}
                            onChange={e => (isExpandedHpi ? setIsExpandedHpi(false) : setIsExpandedHpi(true))}
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon className='app-text-color' />}
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <Typography>History of present illness :</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography variant='caption'>
                                {referItems?.data?.hpi && (
                                  <div style={{ paddingLeft: '8px' }}>
                                    {referItems?.data?.hpi.split('\r').map((item2, index2) => (
                                      <div key={index2}>
                                        <h6>{item2}</h6>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <hr />
                        </Col>
                      </Row>
                    )}
                  </CardContent>
                </Card>
              ))}
            </div>
          ))}
        </div>
      )}

      <SimpleDialog
        open={selectedAttachment}
        titleText='ยืนยันการลบ'
        contentText='ยืนยันที่จะลบไฟล์นี้หรือไม่ ?'
        onSubmit={() =>
          handleDeleteFile({
            idToken: appData?.idToken,
            id: selectedAttachment._id,
            cid: visitData?.visitsData[0]?.cid,
          })
        }
        onClose={() => setSelectedAttachment(null)}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    appProperty: state.appProperty,
    appData: state.appData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appPropertySet: appPropertyData => dispatch(appPropertySet(appPropertyData)),
    fetchPatientsVisitsDetailsSet: dataRequest => dispatch(fetchPatientsVisitsDetailsSet(dataRequest)),
    fetchRefersAccept: dataRequest => dispatch(fetchRefersAccept(dataRequest)),
    fetchRefersReject: dataRequest => dispatch(fetchRefersReject(dataRequest)),
    fetchReferNotificationsRead: dataRequest => dispatch(fetchReferNotificationsRead(dataRequest)),
    deleteAttachment: dataRequest => dispatch(deleteAttachment(dataRequest)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitsRefer));
