import { APPDATA } from '../constants/appData';
import Refers from '../api/Refers';
import ChwReport from '../api/Report/ChwReport';
import ExportReport from '../api/Report/ExportReport';

export function fetchRefers(dataRequest) {
  return {
    type: APPDATA,
    payload: Refers(dataRequest),
  };
}

export function fetchChwRefers(dataRequest) {
  return {
    type: APPDATA,
    payload: ChwReport(dataRequest),
  };
}

export function fetchReportExport(dataRequest) {
  return {
    type: APPDATA,
    payload: ExportReport(dataRequest),
  };
}